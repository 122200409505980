import React, { useEffect } from "react";
import "./About.css"; // Import the CSS file
import Me from "../me.jpeg";
import { useTranslation } from "react-i18next";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const About = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const toggleScrollToTopButton = () => {
      const button = document.getElementById("scroll-to-top-button");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        button.style.display = "block";
      } else {
        button.style.display = "none";
      }
    };

    // Add the scroll event listener
    window.addEventListener("scroll", toggleScrollToTopButton);

    return () => {
      // Remove the scroll event listener when component is unmounted
      window.removeEventListener("scroll", toggleScrollToTopButton);
    };
  }, []);
  return (
    <div className="about-container">
      <div className="top-section3" />
      <p className="about-text">{t("aboutMeLong")}</p>
      <button id="scroll-to-top-button" onClick={scrollToTop}>
        &#8593;
      </button>
    </div>
  );
};

export default About;
