import React from "react";
import "./Profile.css";
import myPhoto from "../profile.png";
import accreditationLogo from "../accreditationlogo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import superProfLogo from "../superprofLogo.png";

const Profile = () => {
  const { t } = useTranslation();
  return (
    <div className="profile-container">
      <div className="profile-image">
        <img src={myPhoto} alt="Your Photo" className="rounded-circle" />
      </div>
      <div className="profile-info">
        <h2 className="profile-name">Oscar Alberigo</h2>
        <p className="profile-description">{t("aboutMeHome")}</p>
        <a
          href="https://www.lta.org.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{ width: "150px", borderRadius: 20 }}
            src={accreditationLogo}
          ></img>
        </a>
        <div>
          <a
            href="https://www.superprof.es/entrenador-dinamico-fusiona-anos-experiencia-como-jugador-con-anos-pericia-como-coach-potenciando-juego.html"
            target="_blank"
            rel="noopener noreferrer"
            className="superprof-link"
          >
            <img
              className="superprof-logo"
              style={{ width: "200px", borderRadius: 20 }}
              src={superProfLogo}
              alt="SuperProf Logo"
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Profile;
