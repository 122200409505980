import React from "react";
import TextBox from "./TextBox";
import "./PricesCards.css";
import { useTranslation } from "react-i18next";

const PricesCards = () => {
  const { t } = useTranslation();

  const boxes = [
    {
      heading: t("privateLessonsHeader"),
      subheading: t("duration") + ": 1 hr",
      text: "36 euros " + t("lights"),
    },
    {
      heading: t("groupLessonsHeader"),
      subheading: t("duration") + ": 1 hr 30 mins",
      text: t("groupPrice"),
    },
    {
      heading: t("competitiveTrainingHeader"),
      subheading: t("duration") + ": 1.5 hrs, " + t("timesPerWeek"),
      text: "",
    },
  ];

  return (
    <div className="card">
      <div className="text-box-container">
        {boxes.map((box, index) => (
          <TextBox
            key={index}
            subheading={box.subheading}
            heading={box.heading}
            text={box.text}
          />
        ))}
      </div>
    </div>
  );
};

export default PricesCards;
