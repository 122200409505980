import React from "react";
import "./ImageWithDescription.css"; // Import your CSS file

const ImageWithDescription = ({
  imageSrc,
  description,
  header,
  invertOrder,
}) => {
  const orderClass = invertOrder ? "text-first" : "image-first";

  return (
    <div className={`image-with-description ${orderClass}`}>
      <div className="image">
        <img src={imageSrc} alt="Image" />
      </div>
      <div className="description">
        {header && <h2>{header}</h2>}
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ImageWithDescription;
