// App.js
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Prices from "./components/Prices";
import Navbar from "./components/Navbar"; // Import your Header component
import Footer from "./components/Footer"; // Import your Footer component
import Blog from "./components/Blog";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (selectedLanguage) => {
    const currentPath = location.pathname;
    const newPath = currentPath.replace(/^\/[a-z]{2}/, `/${selectedLanguage}`);
    i18n.changeLanguage(selectedLanguage);
    navigate(newPath);

    // Update the URL with the selected language
    window.history.replaceState(null, null, newPath);
  };

  useEffect(() => {
    const urlLanguage = location.pathname.split("/")[1]; // Get the language from the URL
    const supportedLanguages = ["en", "es", "cat"]; // Add more languages as needed

    if (supportedLanguages.includes(urlLanguage)) {
      // Change language only if it's a supported language
      i18n.changeLanguage(urlLanguage);
    }
  }, [i18n]);

  return (
    <div>
      <select
        id="languageSelector"
        onChange={(e) => changeLanguage(e.target.value)}
        value={i18n.language}
      >
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="cat">Català</option>
        {/* Add more language options as needed */}
      </select>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:lang/about" element={<About />} />
        <Route path="/:lang/contact" element={<Contact />} />
        <Route path="/:lang/lessons" element={<Prices />} />
        <Route path="/:lang" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/lessons" element={<Prices />} />
        <Route path="/:lang/blog" element={<Blog />} />
        <Route path="/:lang/blog/:id" element={<Blog />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
