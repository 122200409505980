import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import superProfLogo from "../../src/superprofIcon.png";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const handleWhatsAppClick = () => {
    const phoneNumber = "+447823359587"; // Replace with your WhatsApp number
    const message =
      "Hi Oscar, can you give me some more information about your lessons."; // Your desired message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <footer className="footer">
      <div className="footer-links">
        <Link to={`/${i18n.language}/`}>{t("home")}</Link>
        <Link to={`/${i18n.language}/about`}>{t("aboutMe")}</Link>
        <Link to={`/${i18n.language}/lessons`}>{t("prices")}</Link>
        <Link to={`/${i18n.language}/contact`}>{t("book")}</Link>
      </div>
      <div className="social-links">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2>{t("findUs")}</h2>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a
              href="https://www.superprof.es/entrenador-dinamico-fusiona-anos-experiencia-como-jugador-con-anos-pericia-como-coach-potenciando-juego.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={superProfLogo}
                alt="SuperProf Logo"
                className="social-logo"
                style={{ width: "40px", height: "40px" }} // Adjust the width and height as needed
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61553549813511"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/acelikegaudi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram"></i>
            </a>
            <a
              href="https://www.tiktok.com/@AceLikeGaudi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="social-link" icon={faTiktok} />{" "}
              {/* Use the FontAwesome icon for TikTok */}
            </a>
            <a
              href="https://www.youtube.com/channel/UCZCFMmntU941a_p9Lw21OXA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="social-link" icon={faYoutube} />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-text">
        © 2024 Ace Like Gaudi. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
