import React, { useState } from "react";
import "./ReviewsContainer.css";
import StarRating from "./StarRating";
import { useTranslation } from "react-i18next";

const reviewsData = [
  {
    id: 1,
    name: "Nicola",
    stars: 5,
    description:
      "¡Entrenador muy preparado sobre Barcelona! ¡Muy recomendable para aprender brevemente a jugar al tenis!",
  },
  {
    id: 2,
    name: "Will",
    stars: 5,
    description:
      "Oscar es un gran profesor. Me sentí muy cómodo con él. Me enseñó mucho, y, lo que es más importante, me dio confianza en uno mismo. Lo recomiendo mucho!",
  },
  {
    id: 2,
    name: "Alison",
    stars: 5,
    description:
      "Great teacher, very knowledgeable and enthusiastic . Really put me at ease, and hopefully I’ve improved as a result of Oscar’s coaching. Thanks so much.",
  },
  {
    id: 2,
    name: "Julie",
    stars: 5,
    description:
      "I’ve been playing tennis all my life but wanted to up my game - Oscar was quickly able to fix some life-long mistakes I’d been making and that’s made a huge difference already. Thanks Oscar!",
  },
  {
    id: 2,
    name: "Rory",
    stars: 5,
    description:
      "Oscar really creates a fun environment in which to learn. We’ve focussed on my backhand which has improved dramatically in just a couple of lessons. Would highly recommend to anyone",
  },
  {
    id: 2,
    name: "Lesley",
    stars: 5,
    description:
      "Oscar is the best coach! He made the sessions fun and had so much patience too. He gave me tips that have really improved my game. I highly recommend him",
  },
  {
    id: 2,
    name: "Sebastian",
    stars: 5,
    description:
      "Great guy, great tennis coach. Very enjoyable and easy to get along with. He was brilliant at analysing what I was doing wrong and fixed my forehand in one lesson :)",
  },
];

const redirectToProfile = () => {
  // Redirect to the specified URL when a review is clicked
  window.location.href =
    "https://www.superprof.es/entrenador-dinamico-fusiona-anos-experiencia-como-jugador-con-anos-pericia-como-coach-potenciando-juego.html";
};

const ReviewsComponent = () => {
  const { t, i18n } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const visibleReviews = showAll ? reviewsData : reviewsData.slice(0, 4);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      <div className="reviews-container">
        {visibleReviews.map((review) => (
          <div key={review.id} onClick={redirectToProfile} className="review">
            <div className="profile-icon">{review.name.charAt(0)}</div>
            <div className="info">
              <div className="name">{review.name}</div>
              <StarRating stars={review.stars} />
              <div className="description">{review.description}</div>
            </div>
          </div>
        ))}
      </div>
      {reviewsData.length > 4 && (
        <button onClick={toggleShowAll} className="show-more-button">
          {showAll ? t("showless") : t("showmore")}
        </button>
      )}
    </div>
  );
};

export default ReviewsComponent;
