// LanguageSelector.js
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (language) => {
    const newPath = location.pathname.replace(/^\/[a-z]{2}/, `/${language}`);
    i18n.changeLanguage(language);
    navigate(newPath);
  };

  return (
    <div>
      <button onClick={() => changeLanguage("en")}>English</button>
      <button onClick={() => changeLanguage("es")}>Español</button>
      {/* Add more language buttons as needed */}
    </div>
  );
};

export default LanguageSelector;
