import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Logo from "../logo.png";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const changeLanguage = (selectedLanguage) => {
    const currentPath = location.pathname;
    const newPath = currentPath.replace(/^\/[a-z]{2}/, `/${selectedLanguage}`);
    i18n.changeLanguage(selectedLanguage);
    navigate(newPath);

    // Update the URL with the selected language
    window.history.replaceState(null, null, newPath);
  };

  useEffect(() => {
    const urlLanguage = location.pathname.split("/")[1]; // Get the language from the URL
    const supportedLanguages = ["en", "es", "cat"]; // Add more languages as needed

    if (supportedLanguages.includes(urlLanguage)) {
      // Change language only if it's a supported language
      i18n.changeLanguage(urlLanguage);
    }
  }, [i18n]);

  return (
    <div>
      <select
        id="languageSelector"
        onChange={(e) => changeLanguage(e.target.value)}
        value={i18n.language}
      >
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="cat">Català</option>
        {/* Add more language options as needed */}
      </select>
    </div>
  );
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (selectedLanguage) => {
    const currentPath = window.location.pathname;
    const newPath = currentPath.replace(/^\/[a-z]{2}/, `/${selectedLanguage}`);
    i18n.changeLanguage(selectedLanguage);
    window.location.href = newPath;
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <a href={`/${i18n.language}`}>
            <img
              src={Logo}
              style={{ width: "100px", height: "80px" }}
              alt="Logo"
            />
          </a>
        </div>
        <div className={`navbar-links ${isOpen ? "active" : ""}`}>
          <a href={`/${i18n.language}`}>{t("home")}</a>
          <a href={`/${i18n.language}/about`}>{t("aboutMe")}</a>
          <a href={`/${i18n.language}/lessons`}>{t("prices")}</a>
          <a href={`/${i18n.language}/contact`}>{t("book")}</a>
          <a href={`/${i18n.language}/blog`}>Blog</a>
          <LanguageSelector />
          <a href="tel:+34673894555">+34673894555</a>
        </div>
        <div className="navbar-toggle" onClick={toggleNavbar}>
          <div className={`bar1 ${isOpen ? "change" : ""}`}></div>
          <div className={`bar2 ${isOpen ? "change" : ""}`}></div>
          <div className={`bar3 ${isOpen ? "change" : ""}`}></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
