import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Prices.css"; // Import the CSS file
import PricesCards from "./smallcomponents/PricesCards";

const Prices = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="about-container">
      <div className="top-section2" />
      <PricesCards />
      <Link
        to={`/${i18n.language}/contact`}
        className="custom-button2"
        style={{ textDecoration: "none" }}
      >
        {t("bookButton")}
      </Link>
    </div>
  );
};

export default Prices;
