import React from "react";
import TextBox from "./TextBox";
import "./CardComponent.css";
import { useTranslation } from "react-i18next";

const CardComponent = () => {
  const { t } = useTranslation();

  const boxes = [
    {
      heading: t("FAQs1Header"),
      text: t("FAQs1Text"),
    },
    {
      heading: t("FAQs2Header"),
      text: t("FAQs2Text"),
    },
    {
      heading: t("FAQs3Header"),
      text: t("FAQs3Text"),
    },
  ];

  return (
    <div className="card">
      <div className="text-box-container">
        {boxes.map((box, index) => (
          <TextBox key={index} heading={box.heading} text={box.text} />
        ))}
      </div>
    </div>
  );
};

export default CardComponent;
