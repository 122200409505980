import React from "react";
import { useTranslation } from "react-i18next";
import "./Contact.css";

const Contact = () => {
  const { t } = useTranslation();

  const handleWhatsAppClick = () => {
    const phoneNumber = "+34673894555"; // Replace with your WhatsApp number
    const message =
      "Hi Oscar, I would like to book a lesson. Hola Oscar, me gustaría reservar una clase. Hola Òscar, m'agradaria reservar una classe."; // Your desired message
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;

    // Trigger the conversion event
    // eslint-disable-next-line no-undef
    gtag_report_conversion(whatsappUrl);

    // Open WhatsApp link in a new tab
    window.open(whatsappUrl, "_blank");
  };

  const handleEmailClick = () => {
    const email = "oscar.alberigo@gmail.com";
    window.location.href = `mailto:${email}`;
  };

  return (
    <div className="contact-container">
      <div className="top-section" />
      <h1
        style={{
          color: "#4a4a4a",
          margin: "20px",
          fontSize: "40px",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "40px",
        }}
      >
        {t("bookMessage")}
      </h1>
      <div className="contact-info">
        <div onClick={handleWhatsAppClick} className="contact-item whatsapp">
          <i className="fa fa-whatsapp"></i>
          <span>Whatsapp: +34 673 89 45 55</span>
        </div>
        <div className="contact-item email" onClick={handleEmailClick}>
          <i className="fa fa-envelope"></i>
          <span>Email: oscar.alberigo@gmail.com</span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
