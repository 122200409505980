import React from "react";
import "./Profile.css";
import myPhoto from "../profile.png";
import superProfLogo from "../superprofLogo.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SuperProf = () => {
  const { t } = useTranslation();
  return (
    <div className="profile-container">
      <div className="profile-info">
        <a
          href="https://www.superprof.es/entrenador-dinamico-fusiona-anos-experiencia-como-jugador-con-anos-pericia-como-coach-potenciando-juego.html"
          target="_blank"
          rel="noopener noreferrer"
          className="superprof-link"
        >
          <img
            className="superprof-logo"
            style={{ width: "200px", borderRadius: 20 }}
            src={superProfLogo}
            alt="SuperProf Logo"
          ></img>
        </a>
      </div>
    </div>
  );
};

export default SuperProf;
