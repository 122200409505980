import React, { useRef } from "react";
import myVideo from "../metennis.mp4"; // Replace with the actual path to your video file
import "./VideoComponent.css"; // Create a separate CSS file for styling

const VideoComponent = () => {
  const videoRef = useRef(null);

  const handleVideoClick = () => {
    const video = videoRef.current;

    if (video.paused) {
      video.play();
      video.muted = false; // Unmute on user interaction
    } else {
      video.pause();
    }

    // Check if the user is on a mobile device
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // Trigger fullscreen on mobile devices
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
    }
  };

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        onClick={handleVideoClick}
        controls={false} // Hide default controls
        autoPlay
        muted
        playsInline
        className="video-element"
      >
        <source src={myVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoComponent;
