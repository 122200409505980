import React from "react";
import "./CardComponent.css";

const TextBox = ({ heading, subheading, text }) => {
  return (
    <div className="text-box">
      <h3>{heading}</h3>
      <h4>{subheading}</h4>
      <p>{text}</p>
    </div>
  );
};

export default TextBox;
