// Blog.js
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useParams, useHi } from "react-router-dom";
import translationsEn from "../translations/en.json";
import translationsEs from "../translations/es.json";
import translationsCat from "../translations/cat.json";
import "./Blog.css"; // Import the CSS file for styling
import { bab } from "../babolat.webp";

const Blog = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const translations = {
    en: translationsEn,
    es: translationsEs,
    cat: translationsCat,
  };

  const blogPosts = translations[i18n.language].blogPosts;
  const [selectedBlog, setSelectedBlog] = useState(null);

  const handleBlogClick = (index) => {};

  const handleBackToBlogs = () => {
    setSelectedBlog(null);
  };

  if (id !== undefined) {
    const blogPost = blogPosts[id];

    if (!blogPost) {
      return <div>Blog not found</div>;
    }

    return (
      <div className="detailed-view">
        <Link to={`/${i18n.language}/blog`} className="back-link">
          <button className="back-button">{t("back")}</button>
        </Link>
        <img
          className="detailed-image"
          src={require(`../${blogPost.image}`)}
          alt={blogPost.title}
        />
        <h1 className="detailed-title">{blogPost.title}</h1>
        {blogPost.sections.map((section, sectionIndex) => (
          <div className="blog-section" key={sectionIndex}>
            <h2 className="section-heading">{section.heading}</h2>
            <p className="section-content">{section.content}</p>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <h1 className="blog-header">AceLikeGaudi Blog</h1>
      <div className="blog-list">
        {blogPosts.map((blogPost, index) => (
          <div
            className="blog-post"
            key={index}
            onClick={() => handleBlogClick(index)}
          >
            <Link to={`/${i18n.language}/blog/${index}`} className="blog-link">
              <h2 className="blog-title">{blogPost.title}</h2>
            </Link>
            <p className="blog-snippet">
              {blogPost.sections[0].content.substring(0, 100)}...
            </p>
            <p className="blog-snippet">{blogPost.date}</p>

            <img
              className="detailed-image"
              src={require(`../${blogPost.image}`)}
              alt={blogPost.title}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
