import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import "./Profile.css";
import Profile from "./Profile";
import Slowmo from "../slowmo.mp4";
import SuperProf from "./Superprof";
import CardComponent from "../components/smallcomponents/CardComponent";
import ImageWithDescription from "./smallcomponents/ImageWithDescription";
import MyPhoto from "../myphoto.png";
import KidsLessons from "../kids.png";
import ShareIcons from "./smallcomponents/ShareIcons";
import Private from "../private.jpeg";
import Group from "../group.jpg";
import Competitive from "../competitive.jpeg";
import MapComponent from "./MapComponent";
import { useTranslation } from "react-i18next";
import ReviewsComponent from "./ReviewsComponent";
import VideoComponent from "./VideoComponent";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Home = () => {
  const { t, i18n } = useTranslation();
  const websiteUrl = "leccionestenisbarcelona.es";
  useEffect(() => {
    const toggleScrollToTopButton = () => {
      const button = document.getElementById("scroll-to-top-button");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        button.style.display = "block";
      } else {
        button.style.display = "none";
      }
    };

    // Add the scroll event listener
    window.addEventListener("scroll", toggleScrollToTopButton);

    return () => {
      // Remove the scroll event listener when component is unmounted
      window.removeEventListener("scroll", toggleScrollToTopButton);
    };
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  return (
    <>
      <div className="home-screen">
        <video id="video-background" autoPlay muted loop playsInline>
          <source src={Slowmo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="content">
          <h1 className="text-center">{t("header")}</h1>
          <Link
            to={`/${i18n.language}/contact`} // Update the link to include the language
            className="custom-button"
            style={{ textDecoration: "none" }}
          >
            {t("bookButton")}
          </Link>
        </div>
      </div>
      <Profile />
      <ReviewsComponent />
      <VideoComponent />
      <ImageWithDescription
        imageSrc={Private}
        header={t("privateLessonsHeader")}
        description={t("privateLessonsText")}
      />
      <ImageWithDescription
        imageSrc={Group}
        header={t("groupLessonsHeader")}
        description={t("groupLessonsText")}
        invertOrder={true} // Toggle the order for this pair
      />
      <ImageWithDescription
        imageSrc={Competitive}
        header={t("competitiveTrainingHeader")}
        description={t("competitiveTrainingText")}
        invertOrder={false} // Toggle the order for this pair
      />
      <ImageWithDescription
        imageSrc={KidsLessons}
        header={t("kidsHeader")}
        description={t("kidsText")}
        invertOrder={true} // Toggle the order for this pair
      />
      <MapComponent />
      <CardComponent />
      <ShareIcons websiteUrl={websiteUrl} />
      <button id="scroll-to-top-button" onClick={scrollToTop}>
        &#8593;
      </button>
    </>
  );
};

export default Home;
