// StarRating.js
import React from "react";

const StarRating = ({ stars }) => {
  const renderStars = () => {
    const filledStars = Math.floor(stars);
    const halfStar = stars % 1 !== 0;
    const emptyStars = 5 - filledStars - (halfStar ? 1 : 0);

    const starElements = [];

    for (let i = 0; i < filledStars; i++) {
      starElements.push(
        <span key={i} className="star filled-star">
          &#9733;
        </span>
      );
    }

    if (halfStar) {
      starElements.push(
        <span key="half" className="star half-star">
          &#9733;&#189;
        </span>
      );
    }

    for (let i = 0; i < emptyStars; i++) {
      starElements.push(
        <span key={`empty-${i}`} className="star empty-star">
          &#9734;
        </span>
      );
    }

    return starElements;
  };

  return <div className="star-rating">{renderStars()}</div>;
};

export default StarRating;
