import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "./ShareIcons.css"; // Create a CSS file for styling
import { useTranslation } from "react-i18next";

const ShareIcons = ({ websiteUrl }) => {
  const { t } = useTranslation();
  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      websiteUrl
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      websiteUrl
    )}`;
    window.open(facebookUrl, "_blank");
  };

  const shareOnInstagram = () => {
    // Instagram doesn't support direct sharing via URL
    // You can provide a link to your Instagram profile instead
    window.open("https://www.instagram.com/acelikegaudi/", "_blank");
  };

  return (
    <div className="share-icons-container">
      <p className="share-label">{t("share")}</p>
      <div className="share-icons">
        <button onClick={shareOnWhatsApp}>
          <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </button>
        <button onClick={shareOnFacebook}>
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </button>
        <button onClick={shareOnInstagram}>
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </button>
      </div>
    </div>
  );
};

export default ShareIcons;
