// MapComponent.js
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import markerIcon from "../marker.png";

const MapComponent = () => {
  return (
    <MapContainer
      center={[41.344787, 2.112208]} // Initial map center (latitude, longitude)
      zoom={13} // Initial zoom level
      style={{ width: "100%", height: "400px" }} // Set the map size
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={[41.344787, 2.112208]}>
        <Popup>
          <a>
            <a href="https://www.cemtennishospitalet.com/">
              C.E.M Tenis L'Hospitalet
            </a>
          </a>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;
